import styled from 'styled-components';

export const Head = styled.div`
  height: auto;
  padding: 70px;
  text-align: center;
  font-family: 'Figtree-Regular', arial, sans-serif;
  font-size: 22px;
  line-height: 32px;
`;

export const LogoStyle = styled.img`
  margin-bottom: 0;
  width: 100%;
  max-width: 350px;
  height: auto;
  border: 0;
  vertical-align: top;
  @media (max-width: 767px) {
    max-width: 250px;
    width: 100%;
  }
`;

export const HeaderH2 = styled.h2`
  padding: 0;
  margin: 0 0 30px 0;
  font-family: 'Figtree-Black', arial, sans-serif;
  font-size: 45px;
  line-height: 55px;
  text-transform: uppercase;
  color: #6e2b8b;
  @media (max-width: 767px) {
    font-size: 30px;
    line-height: 35px;
  }
`;

export const ParaText = styled.p`
  margin: 0 0 30px 0;
  padding: 0;
  font-family: 'Figtree-Regular', arial, sans-serif;
  font-size: 22px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: rgba(0, 0, 0, 0.7);
  b {
    font-family: 'Figtree-Bold', arial, sans-serif;
  }
`;
